<!-- 设备活跃分布 -->
<template>
  <div class="w-full border p-4 bg-white rounded select-none">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 mb-2"
    >
      设备活跃分布
    </div>
    <div class="chart">
      <div id="deviceUseChart" ref="deviceUseChart" class="h-96"></div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">

export default {
  data () {
    return {
      option: {},
      deviceGraphObj: {}
    }
  },
  methods: {
    homeV2DeviceGraph () {
      this.$api.homeApi.homeV2DeviceGraph().then(res => {
        this.deviceGraphObj = res.data
        const xAxisData = this.deviceGraphObj.map(item => item.time)
        const yAxisData = this.deviceGraphObj.map(item => item.count)
        this.draw(xAxisData, yAxisData)
      })
    },
    draw (xAxisData, yAxisData) {
      const deviceUseChart = this.$echarts.init(this.$refs.deviceUseChart)
      this.option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {

            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(255, 158, 68)'
                },
                {
                  offset: 1,
                  color: 'rgb(255, 70, 131)'
                }
              ])
            },
            name: '设备活跃分布',
            data: yAxisData,
            type: 'line'
          }
        ]
      }
      deviceUseChart.setOption(this.option)
    }
  },
  mounted () {
    this.homeV2DeviceGraph()
  }
}
</script>
