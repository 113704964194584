<!-- 客户存储使用情况 -->
<template>
  <div class="w-full border p-4 bg-white rounded select-none h-full">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 mb-2"
    >
      存储使用量排名 (TOP 10)
    </div>
    <div class="rank-box overflow-y-scroll mt-4 h-80">
      <div
          class="rank-item h-12 flex align-items-center p-2 mt-4"
          v-for="(item, index) in containerUseRank"
          :key="index"
      >
        <div
            class="num bg-gray-300 w-8 h-8 rounded-full flex justify-content-center align-items-center text-white"
            :class="[
            { 'bg-yellow-500': index === 0 },
            { 'bg-yellow-400': index === 1 },
            { 'bg-yellow-300': index === 2 }
          ]"
        >
          {{ index + 1 }}
        </div>
        <div class="flex flex-col pl-4 w-11/12">
          <div class="client-name text-xs">
            {{ item.customerName }}
          </div>
          <div class="progress">
            <el-progress class="w-full" :percentage="item.ratio"></el-progress>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      containerUseRank: []
    }
  },
  methods: {
    getContainerUseRank () {
      this.$api.home.containerUseRank().then(res => {
        this.containerUseRank = res.data
      })
    }
  },
  mounted () {
    this.getContainerUseRank()
  }
}
</script>
