<!-- 数据统计 -->
<template>
  <div class="w-full p-4 select-none grid grid-cols-5 gap-4">
    <div
        class="item bg-white w-full p-4 border border-gray-200 rounded-md shadow-md"
    >
      <div class="title text-sm text-gray-500">设备数量(台)</div>
      <div class="count font-bold text-3xl mt-3">
        {{ homeV2SelectData.deviceTotalCount }}
        <span class="text-xs text-gray-400">台 </span>
        <span class="text-xs text-gray-400 ml-1"
        >/ {{ homeV2SelectData.deviceActivateCount }}台 已激活</span
        >
      </div>
      <div
          class="text-xs mt-2 text-gray-500 cursor-pointer"
          @click="$router.push({ name: 'sysDevice' })"
      >
        <i class="el-icon-setting mr-1"></i>进入设备库
      </div>
    </div>
    <div
        class="item bg-white w-full p-4 border border-gray-200 rounded-md shadow-md"
    >
      <div class="title text-sm text-gray-500 cursor-pointer">SIM卡(张)</div>
      <div class="count font-bold text-3xl mt-3">
        {{ homeV2SelectData.simTotalCount }}
        <span class="text-xs text-gray-400">张 </span>
        <!--        <span class="text-xs text-gray-400 ml-1">/ ?张 未激活</span>-->
      </div>
      <div
          class="text-xs mt-2 text-gray-500 cursor-pointer"
          @click="$router.push({ name: 'simList' })"
      >
        进入SIM卡管理<i class="el-icon-top-right"></i>
      </div>
    </div>
    <div
        class="item bg-white w-full p-4 border border-gray-200 rounded-md shadow-md"
    >
      <div class="title text-sm text-gray-500">客户总数</div>
      <div class="count font-bold text-3xl mt-3">
        {{ homeV2SelectData.customerTotalCount }}
        <span class="text-xs text-gray-400">位 </span>
      </div>
      <div
          class="text-xs mt-2 text-gray-500 cursor-pointer"
          @click="$router.push({ name: 'createClient' })"
      >
        添加租户
      </div>
    </div>
    <div
        class="item bg-white w-full p-4 border border-gray-200 rounded-md shadow-md"
    >
      <div class="title text-sm text-gray-500">本月存储用量</div>
      <div class="count font-bold text-3xl mt-3">
        {{ homeV2SelectData.monthUseStorageAmount }}
        <span class="text-xs text-gray-400">GB </span>
      </div>
      <div class="text-xs mt-2">
        日环比
        <span>
          <i class="el-icon-caret-top text-green-700"></i>
          + {{ homeV2SelectData.storageDayRatio }}%
        </span>
        月环比
        <span>
          <i class="el-icon-caret-bottom text-red-700"></i>
          - {{ homeV2SelectData.storageMonthRatio }}%
        </span>
      </div>
    </div>
    <div
        class="item bg-white w-full p-4 border border-gray-200 rounded-md shadow-md"
    >
      <div class="title text-sm text-gray-500">本月流量使用</div>
      <div class="count font-bold text-3xl mt-3">
        {{ homeV2SelectData.monthUseTrafficAmount }}
        <span class="text-xs text-gray-400">GB </span>
      </div>
      <div class="text-xs mt-2">
        日环比
        <span>
          <i class="el-icon-caret-top text-green-700"></i>
          + {{ homeV2SelectData.trafficDayRatio }}%
        </span>
        月环比
        <span>
          <i class="el-icon-caret-bottom text-red-700"></i>
          - {{ homeV2SelectData.trafficMonthRatio }}%
        </span>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  props: {
    homeV2SelectData: {
      type: Object,
      default: () => {
        return {
          deviceTotalCount: 0,
          simTotalCount: 0,
          customerTotalCount: 0,
          monthUseStorageAmount: 0,
          monthUseTrafficAmount: 0
        }
      }
    }
  },
  methods: {},
  mounted () {
  }
}
</script>
