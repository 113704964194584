<!-- 本期账单 -->
<template>
  <div class="w-full border p-4 bg-white rounded select-none h-full">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 mb-2"
    >
      本期账单
      <span class="text-xs"
      >({{ billObj.startDate || '/' }} ~ {{ billObj.endDate || '/' }})</span
      >
      <span class="float-right text-xs text-gray-400"
      >出账日期: {{ billObj.createDate || '/' }}</span
      >
    </div>
    <div class="box text-sm grid grid-cols-2 leading-7">
      <div class="item flex col-span-2">
        <div class="label mr-2 text-gray-500 w-24 font-bold">
          <div class="float-left">收费项目</div>
          <el-tooltip class="item" effect="dark" placement="top">
            <div class="float-left ml-1">
              <i class="el-icon-question"></i>
            </div>
            <div slot="content" class="leading-6">
              <div>
                平台使用费：平台使用费平台使用费平台使用费平台使用费平台使用费平台使用费
              </div>
              <div>
                平台维护费用：平台维护费用平台维护费用平台维护费用平台维护费用平台维护费用
              </div>
              <div>存储费用：存储费用存储费用存储费用存储费用</div>
              <div>流量费用：流量费用流量费用流量费用流量费用流量费用</div>
              <div>
                License授权费：License授权费License授权费License授权费License授权费
              </div>
              <div>如对账单有疑问，请联系客户经理。</div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div
          class="item py-1 flex align-items-center lg:col-span-1 col-span-2"
          v-for="(item, index) in billObj.billDetailList"
          :key="index"
      >
        <div class="label mr-2 text-gray-500 text-xs w-24">{{ item.name }}</div>
        <div class="value text-xs text-gray-700">￥ {{ item.chargeFee }}元</div>
      </div>
      <div
          v-if="billObj.billDetailList && billObj.billDetailList.length === 0"
          class="h-16 text-center col-span-2 row-span-2 align-items-center"
      >
        暂无账单
      </div>
      <div
          class="item py-1 flex align-items-center col-span-2"
          v-if="billObj.billDetailList && billObj.billDetailList.length > 0"
      >
        <div class="label mr-2 text-gray-400 w-24 text-xs cursor-pointer">
          查看详情
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      billObj: {
        startDate: '',
        endDate: '',
        createDate: '',
        billDetailList: []
      }
    }
  },
  methods: {
    getHomeV2Bill () {
      this.$api.homeApi.homeV2Bill().then(res => {
        this.billObj = res.data
        // this.billObj.billDetailList = [
        //   {
        //     name: '平台使用费',
        //     chargeFee: '2346.00'
        //   },
        //   {
        //     name: '平台维护费用',
        //     chargeFee: '2346.00'
        //   },
        //   {
        //     name: '存储费用',
        //     chargeFee: '2346.00'
        //   },
        //   {
        //     name: '流量费用',
        //     chargeFee: '2346.00'
        //   },
        //   {
        //     name: 'License授权费',
        //     chargeFee: '2346.00'
        //   }
        // ]
        this.billObj.billDetailList = this.billObj.billDetailList ? this.billObj.billDetailList : []
      })
    }
  },
  mounted () {
    this.getHomeV2Bill()
  }
}
</script>
