<!-- License 生命周期分布 -->
<template>
  <div class="w-full border p-4 bg-white rounded select-none">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 mb-2"
    >
      License 生命周期分布
    </div>
    <div class="chart">
      <div id="licenseChart" ref="licenseChart" class="h-96"></div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      option: {},
      xLine: [],
      dataLine: [150, 230, 224, 218, 135, 147, 260],
      licenseGroupObj: {}
    }
  },
  methods: {
    getHomeV2LicenseGroup () {
      this.$api.homeApi.homeV2LicenseGroup().then(res => {
        this.licenseGroupObj = res.data
        const data = this.licenseGroupObj.map(item => {
          return {
            value: item.count,
            name: item.type === 1 ? '测试License' : '正式License'
          }
        })
        this.draw(data)
      })
    },
    draw (data) {
      const licenseChart = this.$echarts.init(this.$refs.licenseChart)
      this.option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right'
        },
        series: [
          {
            top: 30,
            name: 'License 生命周期分布',
            type: 'pie',
            radius: ['40%', '80%'],
            emphasis: {
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data
          }
        ]
      }
      licenseChart.setOption(this.option)
    }
  },
  mounted () {
    this.getHomeV2LicenseGroup()
  }
}
</script>
