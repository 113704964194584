<!-- 用量预警 -->
<template>
  <div class="w-full border p-4 bg-white rounded select-none h-full">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 mb-2"
    >
      业务告警
    </div>
    <div class="box grid grid-cols-3 divide-x">
      <div class="item cursor-pointer py-3">
        <div
            class="count text-4xl font-bold flex justify-content-center align-items-center text-red-600"
        >
          {{ homeV2SelectData.storageWarnCount }}
        </div>
        <div
            class="label mt-1 text-xs flex justify-content-center align-items-center"
        >
          存储即将达量
        </div>
      </div>

      <div class="item cursor-pointer py-3">
        <div
            class="count text-4xl font-bold flex justify-content-center align-items-center text-red-600"
        >
          {{ homeV2SelectData.trafficWarnCount }}
        </div>
        <div
            class="label mt-1 text-xs flex justify-content-center align-items-center"
        >
          流量即将达量
        </div>
      </div>

      <div class="item cursor-pointer py-3">
        <div
            class="count text-4xl font-bold flex justify-content-center align-items-center text-red-600"
        >
          {{ homeV2SelectData.licenseWarnCount }}
        </div>
        <div
            class="label mt-1 text-xs flex justify-content-center align-items-center"
        >
          License即将达量
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
export default {
  data () {
    return {}
  },
  props: {
    homeV2SelectData: {
      type: Object,
      default: () => {
        return {
          storageWarnCount: 0,
          trafficWarnCount: 0,
          licenseWarnCount: 0
        }
      }
    }
  },
  methods: {},
  mounted () {
  }
}
</script>
