<!-- 流量池 -->
<template>
  <div class="w-full border p-4 bg-white rounded select-none h-full">
    <div
        class="title text-gray-700 font-bold text-sm border-l-4 border-blue-900 pl-2 mb-2"
    >
      流量池
    </div>
    <div id="netWorkPoolChart" ref="netWorkPoolChart" class="h-32"></div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import 'echarts-liquidfill'

export default {
  data () {
    return {
      option: {},
      flowPoolObj: {
        totalTraffic: 10,
        useTraffic: 1
      }
    }
  },
  methods: {
    getHomeV2FlowPool () {
      this.$api.homeApi.homeV2FlowPool().then(res => {
        this.flowPoolObj = res.data
        if (this.flowPoolObj.useTraffic && this.flowPoolObj.totalTraffic) {
          this.number = (this.flowPoolObj.useTraffic / this.flowPoolObj.totalTraffic) * 100
        } else {
          this.number = 0
        }
        this.draw()
      })
    },
    draw () {
      const netWorkPoolChart = this.$echarts.init(this.$refs.netWorkPoolChart)
      const This = this
      this.option = {
        series: [{
          type: 'liquidFill',
          radius: '75%',
          center: ['50%', '45%'],
          data: [This.number / 100, This.number / 100, This.number / 100],
          backgroundStyle: {
            borderWidth: 1,
            color: 'rgb(41,47,59,1)'
          },
          label: {
            normal: {
              formatter: This.number + '%',
              textStyle: {
                fontSize: 15
              }
            }
          },
          outline: {
            show: false
          }
        }]
      }
      netWorkPoolChart.setOption(this.option)
    }
  },
  mounted () {
    this.getHomeV2FlowPool()
  }
}
</script>
