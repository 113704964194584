<!-- 首页模块 -->
<template>
  <div>
    <div class="grid grid-cols-5 gap-4">
      <div class="col-span-5">
        <dataStatistics :homeV2SelectData="homeV2SelectData"></dataStatistics>
      </div>
      <div class="col-span-2">
        <userWarning :homeV2SelectData="homeV2SelectData"></userWarning>
      </div>
      <div class="col-span-2">
        <financeBox></financeBox>
      </div>
      <div class="col-span-1">
        <networkPool></networkPool>
      </div>
      <div class="col-span-2">
        <networkChart></networkChart>
      </div>
      <div class="col-span-3">
        <deviceUseChart></deviceUseChart>
      </div>
      <div class="col-span-2">
        <licenseChart></licenseChart>
      </div>
      <div class="col-span-3">
        <storageUse></storageUse>
      </div>
      <!--      <div class="col-span-2">-->
      <!--        <storageChart></storageChart>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import userWarning from 'components/home/userWarning'
import dataStatistics from 'components/home/dataStatistics'
import financeBox from 'components/home/financeBox'
import networkPool from 'components/home/networkPool'
import networkChart from 'components/home/networkChart'
import deviceUseChart from 'components/home/deviceUseChart'
import licenseChart from 'components/home/licenseChart'
import storageUse from 'components/home/storageUse'
// import storageChart from 'components/home/storageChart'

export default {
  data () {
    return {
      homeV2SelectData: {}
    }
  },
  components: {
    userWarning,
    dataStatistics,
    financeBox,
    networkPool,
    networkChart,
    deviceUseChart,
    licenseChart,
    storageUse
    // storageChart
  },
  methods: {
    homeV2Select () {
      this.$api.homeApi.homeV2Select().then(res => {
        this.homeV2SelectData = res.data
      })
    }
  },
  mounted () {
    this.homeV2Select()
  }
}
</script>
